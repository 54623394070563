import React from 'react';

import { Router } from '@reach/router';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';

import RequireLogin from '@domui-components/Auth/RequireLogin';
import NotFound from '@domui-components/Routing/NotFound';
import ClubInvoiceReport from '@domui-presenters/web/pages/ClubInvoiceReport';

const ClubInvoiceReportPage: React.FC<{
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  return (
    <RequireLogin>
      <Router basepath={`/${languagePrefix}`}>
        <ClubInvoiceReport
          path="/domui/club-invoice-report/:clubId"
          clubId="is set from path"
        />
      </Router>
    </RequireLogin>
  );
};

export default ClubInvoiceReportPage;
