export const getOfficersPagePath = (clubId?: string) =>
  `/domui/club/${clubId}/officers`;

export const getAddMemberPagePath = (clubId?: string) =>
  `/domui/club/${clubId}/add-member`;

export const getClubDetailsPath = (clubId?: string | null) =>
  `/domui/club/${clubId}/details`;

export const getClubMembersPath = (clubId?: string) =>
  `/domui/club/${clubId}/roster`;

export const getEditMembershipPath = (clubId: string, individualId: string) =>
  `/domui/club/${clubId}/edit-membership/${individualId}`;

export const getDelegationRolesPath = () => `/delegation/roles`;

export const getClubFinancePath = (clubId: string) =>
  `/domui/club/${clubId}/finances`;

export const getClubInvoicePath = (clubId: string) =>
  `/domui/club-invoice/${clubId}`;

export const getReportsPath = () => `/domui/membership-and-foundation-reports`;
