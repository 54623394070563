import Workflow from './base';

import { DomuiError, DynamicData } from '@domui-domain/type';

class Organization extends Workflow {
  constructor() {
    super('organizationwf');
  }

  async getDominoOrganizationId(disId: string) {
    this.setAction('getOrganizationId');
    this.setData({
      idType: 'DISID',
      value: disId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch organization id');
    }
  }

  async getOrganization(dominoId: string) {
    this.setAction('getOrganization');
    this.setData({
      id: dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch get organization.');
    }
  }

  async addMeetingDetails(orgId: string, data: DynamicData) {
    this.setAction('addMeetingDetails');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async updateClubContactInfo(orgId: string, data: DynamicData) {
    this.setAction('updateClubContactInfo');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async updateMeeting(orgId: string, data: DynamicData) {
    this.setAction('updateMeeting');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async peopleGraphDetails(orgId: string, data: DynamicData) {
    this.setAction('peopleGraphDetails');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async getClubLeadership(
    domOrgId: string,
    startDate: string,
    endDate: string,
    pageNumber: number,
    pageSize: number
  ) {
    this.setAction('getClubLeadership');
    this.setData({
      orgId: domOrgId,
      startDate,
      endDate,
      pageNumber,
      pageSize,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch get club leadership.');
    }
  }

  async distClubsStatistics(distOrgId: string, data: DynamicData) {
    this.setAction('distClubsStatistics');
    this.setData({
      id: distOrgId,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchDistrictOfficers(orgId: string, data: DynamicData) {
    this.setAction('fetchDistrictOfficers');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async DistrictAssistantGovernors(
    districtId: string,
    pageNumber: number,
    pageSize: number,
    requestBody: DynamicData
  ) {
    this.setAction('districtAssistantGovernors');
    this.setData({
      orgId: districtId,
      pageNumber,
      pageSize,
      requestBody,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get district assistant governors details.');
    }
  }

  async DistrictCommitteeAppointments(
    districtId: string,
    payloadData: DynamicData
  ) {
    this.setAction('districtCommitteeAppointments');
    this.setData({
      orgId: districtId,
      payloadData,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get district committee appointments details.');
    }
  }

  async fetchOrgDisplayInfo(id: string) {
    this.setAction('fetchOrgDisplayInfo');
    this.setData({ id });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch Org Display Info.');
    }
  }

  async organizationTypeRoleMapping(orgId: string) {
    this.setAction('organizationTypeRoleMapping');
    this.setData({
      id: orgId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch get organization type role.');
    }
  }

  async getRelations(dominoId: string) {
    this.setAction('getRelations');
    this.setData({
      id: dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch get organization Relations.');
    }
  }

  async getOrganizationTypeRoleMappingByOrganizationId(dominoId: string) {
    this.setAction('getOrganizationTypeRoleMappingByOrganizationId');
    this.setData({
      id: dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch get organization Role mapping.');
    }
  }

  async findClubsSearch(
    pageNumber: number,
    pageSize: number,
    data: DynamicData
  ) {
    this.setAction('findClubsSearch');
    this.setData({
      pageNumber,
      pageSize,
      putData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get  club search  info');
    }
  }

  async getAllDistricts() {
    this.setAction('getAllDistricts');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch additional participating districts.');
    }
  }

  async getAllDistrictClubs(
    pageNumber: number,
    pageSize: number,
    orgId: string,
    data: DynamicData
  ) {
    this.setAction('getAllDistrictClubs');
    this.setData({
      pageNumber,
      pageSize,
      orgId,
      putData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch clubs  .');
    }
  }

  async deleteClubMeeting(dominoId: string) {
    this.setAction('deleteClubMeeting');
    this.setData({ dominoId });
    try {
      const res = await this.deleteCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }
}

export default Organization;
